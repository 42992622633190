/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-02-17",
    versionChannel: "nightly",
    buildDate: "2024-02-17T00:05:19.264Z",
    commitHash: "fe2ed81d8aa074038ecabe11fb168abc381f788b",
};
